@import "./base/index.scss";
@import "./variables/index.scss";

body {
  margin: 0;
  background: #f9f8f8;
  font-family: "Manrope", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pointer,
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.overflow {
  overflow: auto;
}

.col {
  flex: 1 0 0%;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.w-90 {
  width: 90% !important;
}

.popover-p-0 .ant-popover-inner-content {
  padding: 0 !important;
}

.w-fit-content {
  width: fit-content !important;
}

.w-max-content {
  width: max-content;
}

.w-auto {
  width: auto;
}

.h-50 {
  height: 50% !important;
}

.h-90 {
  height: 90% !important;
}

.h-100 {
  height: 100%;
}

.display-none {
  display: none !important;
}

.h-max-content {
  height: max-content;
}

.h-fit-content {
  height: fit-content;
}

.disabled {
  cursor: default;
  pointer-events: none;
  user-select: none;
}

.white-space-nowrap {
  white-space: nowrap;
}

.border-solid {
  border: 1px solid;
}

.border-solid {
  border: 1px solid;
}

.hidden {
  display: none !important;
}

.visibility-hidden {
  display: none !important;
}

.flex-1 {
  flex: 1;
}

.break-word {
  word-break: break-word;
}

.no-underline {
  text-decoration: none;
}

.no-decoration {
  text-decoration: none;
  color: unset;
}

.icon-btn {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #e1deda;
  background: #fff;
}

.handle {
  opacity: 0;
}

.word-elipses {
  width: 210px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ellipsis {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any content that overflows its container */
  text-overflow: ellipsis; /* Display an ellipsis (...) to represent overflow */
}

.anchor {
  cursor: pointer;

  &:hover {
    color: #ff7b00;
    text-decoration: underline;
  }
}
