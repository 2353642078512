$fontSize: (
  10: 10px,
  12: 12px,
  14: 14px,
  16: 16px,
  24: 24px,
  20: 20px,
  18: 18px,
  30: 30px,
  32: 32px,
);

$textAlignment: (left, right, center, justify, initial, inherit);

$textTransform: (capitalize, uppercase, lowercase, none);

@each $key, $value in $fontSize {
  .fs-#{$key} {
    font-size: #{$value} !important;
  }
}

$fontWeights: (400, 500, 600, 700, 800, 900);

@each $key in $fontWeights {
  .fw-#{$key} {
    font-weight: #{$key};
  }
}

@each $value in $textAlignment {
  .txt-align-#{$value} {
    text-align: #{$value} !important;
  }
}

@each $key in $textTransform {
  .txt-transform-#{$key} {
    text-transform: #{$key};
  }
}

// COLOR SCHEMA
.subtle {
  color: #7c7972;
}

// FONT WEIGHT
.w-regular {
  font-weight: 400 !important;
}

.w-medium {
  font-weight: 500 !important;
}

.w-semi-bold {
  font-weight: 600 !important;
}

.w-bold {
  font-weight: 700 !important;
}

// OTHERS
.line-through {
  text-decoration: line-through;
}

.text-center {
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

// Typography V2: We need to remove above support, so that we move forward compatible
.text {
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  letter-spacing: var(--letter-spacing-default);
  color: var(--text-color-default);
  font-weight: var(--font-weight-normal);

  // FONT-WEIGHT
  &.normal {
    font-weight: var(--font-weight-normal);
  }
  &.medium {
    font-weight: var(--font-weight-medium);
  }
  &.semi-bold {
    font-weight: var(--font-weight-semi-bold);
  }
  &.bold {
    font-weight: var(--font-weight-bold);
  }

  // COLOR
  &.default {
    color: var(--text-color-default);
  }
  &.subtle {
    color: var(--text-color-subtle);
  }
  &.muted {
    color: var(--text-color-muted);
  }
  &.disabled {
    color: var(--text-color-disabled);
  }
  &.placeholder {
    color: var(--text-color-placeholder);
  }
  &.error {
    color: var(--text-color-error);
  }

  // TEXT
  &.tiny {
    font-size: var(--font-size-tiny);
    line-height: var(--line-height-tiny);
    letter-spacing: var(--letter-spacing-tiny);
  }
  &.small {
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    letter-spacing: var(--letter-spacing-small);
  }
  &.default {
    font-size: var(--font-size-default);
    line-height: var(--line-height-default);
    letter-spacing: var(--letter-spacing-default);
  }
  &.large {
    font-size: var(--font-size-large);
    line-height: var(--line-height-large);
    letter-spacing: var(--letter-spacing-large);
  }
  &.xl {
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);
    letter-spacing: var(--letter-spacing-xl);
  }
  &.xxl {
    font-size: var(--font-size-xxl);
    line-height: var(--line-height-xxl);
    letter-spacing: var(--letter-spacing-xxl);
  }
  &.xxxl {
    font-size: var(--font-size-xxxl);
    line-height: var(--line-height-xxxl);
    letter-spacing: var(--letter-spacing-xxxl);
  }
}

// GLOBAL-FONT-SIZE
.fs {
  &-tiny {
    font-size: var(--font-size-tiny);
  }
  &-small {
    font-size: var(--font-size-small);
  }
  &-default {
    font-size: var(--font-size-default);
  }
  &-large {
    font-size: var(--font-size-large);
  }
  &-xl {
    font-size: var(--font-size-xl);
  }
  &-xxl {
    font-size: var(--font-size-xxl);
  }
  &-xxxl {
    font-size: var(--font-size-xxxl);
  }
}

// GLOBAL-FONT-WEIGHT
.fw {
  &-normal {
    font-weight: var(--font-weight-normal);
  }
  &-medium {
    font-weight: var(--font-weight-medium);
  }
  &-semi-bold {
    font-weight: var(--font-weight-semi-bold);
  }
  &-bold {
    font-weight: var(--font-weight-bold);
  }
}

// GLOBAL-LINE-HEIGHT
.line-height {
  &-tiny {
    line-height: var(--line-height-tiny);
  }
  &-small {
    line-height: var(--line-height-small);
  }
  &-default {
    line-height: var(--line-height-default);
  }
  &-large {
    line-height: var(--line-height-large);
  }
  &-xl {
    line-height: var(--line-height-xl);
  }
  &-xxl {
    line-height: var(--line-height-xxl);
  }
  &-xxxl {
    line-height: var(--line-height-xxxl);
  }
}

// GLOBAL-LETTER-SPACING
.letter-spacing {
  &-tiny {
    letter-spacing: var(--letter-spacing-tiny);
  }
  &-small {
    letter-spacing: var(--letter-spacing-small);
  }
  &-default {
    letter-spacing: var(--letter-spacing-default);
  }
  &-large {
    letter-spacing: var(--letter-spacing-large);
  }
  &-xl {
    letter-spacing: var(--letter-spacing-xl);
  }
  &-xxl {
    letter-spacing: var(--letter-spacing-xxl);
  }
  &-xxxl {
    letter-spacing: var(--letter-spacing-xxxl);
  }
}

// GLOBAL-TEXT-COLOR
.text-color {
  &-default {
    color: var(--text-color-default);
  }
  &-subtle {
    color: var(--text-color-subtle);
  }
  &-muted {
    color: var(--text-color-muted);
  }
  &-disabled {
    color: var(--text-color-disabled);
  }
  &-placeholder {
    color: var(--text-color-placeholder);
  }
  &-error {
    color: var(--text-color-error);
  }
}
