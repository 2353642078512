$spacings: (
  0: 0px,
  1: 4px,
  2: 8px,
  6: 6px,
  8: 8px,
  10: 10px,
  12: 12px,
  14: 14px,
  16: 16px,
  3: 16px,
  20: 20px,
  24: 24px,
  4: 24px,
  32: 32px,
  36: 36px,
  5: 48px,
  40: 40px,
  44: 44px,
  60: 60px,
  70: 70px,
  72: 72px,
  auto: auto,
);

@each $key, $value in $spacings {
  .m-#{$key} {
    margin: #{$value} !important;
  }

  .mx-#{$key} {
    margin-right: #{$value} !important;
    margin-left: #{$value} !important;
  }

  .my-#{$key} {
    margin-top: #{$value} !important;
    margin-bottom: #{$value} !important;
  }

  .mt-#{$key} {
    margin-top: #{$value} !important;
  }

  .mr-#{$key} {
    margin-right: #{$value} !important;
  }

  .mb-#{$key} {
    margin-bottom: #{$value} !important;
  }

  .ml-#{$key} {
    margin-left: #{$value} !important;
  }

  .p-#{$key} {
    padding: #{$value} !important;
  }

  .px-#{$key} {
    padding-right: #{$value} !important;
    padding-left: #{$value} !important;
  }

  .py-#{$key} {
    padding-top: #{$value} !important;
    padding-bottom: #{$value} !important;
  }

  .pt-#{$key} {
    padding-top: #{$value} !important;
  }

  .pr-#{$key} {
    padding-right: #{$value} !important;
  }

  .pb-#{$key} {
    padding-bottom: #{$value} !important;
  }

  .pl-#{$key} {
    padding-left: #{$value} !important;
  }

  .bSr-#{$key} {
    border-radius: #{$value} !important;
  }

  .top-#{$key} {
    top: #{$value} !important;
  }

  .bottom-#{$key} {
    bottom: #{$value} !important;
  }

  .right-#{$key} {
    right: #{$value} !important;
  }

  .left-#{$key} {
    left: #{$value} !important;
  }
}

$gaps: (
  2: 2px,
  4: 4px,
  6: 6px,
  8: 8px,
  12: 12px,
  16: 16px,
  24: 24px,
  32: 32px,
  48: 48px,
);

@each $key, $value in $gaps {
  .col-gap-#{$key} {
    column-gap: #{$value};
  }

  .row-gap-#{$key} {
    row-gap: #{$value};
  }
}

.mh-100 {
  max-height: 100%;
}

.h-100 {
  height: 100%;
}
