.chat-list {
  padding: 12px;
  flex: 1;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .agent-session-chat {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 28px 0px;

    .session-border {
      width: 60px;
      height: 0.5px;
      border: 1px solid #b5b1ad;
    }
  }
}

.send-icon {
  cursor: pointer;
}

.chatbot-chat-name-circle {
  height: 30px;
  width: 35px;
  align-self: end;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000 !important;
}

.chatbot-chat-message-box {
  border-radius: 16px 16px 16px 0;
  background: #f9f8f8;
  color: #282624;
  width: auto !important;
}

.chatbot-chat-message-box-agent {
  border-radius: 16px 16px 0 16px;
  background-color: #797979;
  // max-width: 70%;
  align-items: end;
}

.typewriter {
  // display: inline-block;
  overflow: hidden;
  animation:
    typing 1s steps(40, end),
    blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #000;
  }
}

.typing-indicator {
  display: flex;
  align-items: center;
  height: 12px;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.25s;
}
.typing span:last-child {
  animation-delay: 0.5s;
}

.dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #6d6d6d;
  border-radius: 50%;
  margin: 0 2px;
  animation: bounce 1s infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-delay: 0s;
}

@keyframes bounce {
  0% {
    margin-bottom: 0;
  }

  25% {
    margin-bottom: 1px;
  }

  50% {
    margin-bottom: 2px;
  }

  75% {
    margin-bottom: 1px;
  }

  100% {
    margin-bottom: 0;
  }
}

@keyframes pulse {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
    opacity: 0.3;
  }

  50% {
    transform: scale(1.2);
    opacity: 1;
  }
}

.text-message-line-break {
  white-space: pre-line;
}

.bottom-bar {
  border-radius: 0px 0px 16px 16px;
  background-color: #f3f2f0;
}

.persona-tags-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .persona-tag {
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #e1deda;
    background: #fff;
    cursor: pointer;
  }
}
