@import "../variables/colors.scss";

@each $baseColor, $variants in $colors-list {
  @each $variantName, $value in $variants {
    .bg-#{$baseColor}-#{$variantName} {
      background-color: #{$value} !important;
    }
    .border-color-#{$baseColor}-#{$variantName} {
      border-color: #{$value};
    }
    .svg-path-fill-#{$baseColor}-#{$variantName} {
      path {
        fill: #{$value};
      }
    }
    .#{$baseColor}-#{$variantName} {
      color: #{$value} !important;
    }
  }
}
